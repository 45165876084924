import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import theme from "../../theme";

const PrimaryButton = (props) => {
  return (
    <button {...props} onClick={props.onClick} className={`${props.className}`}>
      {props.children}
    </button>
  );
};

function MuiButton(props) {
  return (
    <Box sx={{ "& > button": { width: "100%" } }}>
      <LoadingButton
        onClick={props.onClick}
        loading={props.loading}
        type={props.type}
        disabled={props.isDisabled}
        variant="contained"
        sx={{
          height: props.height,
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          textTransform: "capitalize",
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        {props.children}
      </LoadingButton>
    </Box>
  );
}

function Link(props) {
  return (
    <Box
      onClick={props.onClick}
      sx={{
        textTransform: "lowercase",
      }}
    >
      <Button variant="text">{props.children}</Button>
    </Box>
  );
}

const Participate_Button = (props) => {
  const { t } = useTranslation("common");

  return (
    <div
      {...props}
      onClick={props.onClick}
      className={`bg-primary sm2:w-[120px] w-[100px] sm2:h-[30px] h-[27px] rounded-2xl text-center flex items-center justify-center hover:cursor-pointer hover:opacity-80 ${props.className}`}
    >
      <p className="text-white font-semibold md:text-[14px] sm2:text-[12px] text-[11px] tracking-wider">
        {props.title ? props.title : t("home.participate")}
      </p>
    </div>
  );
};

const Confirm_Button = (props) => {
  let { onClick, className, children, type } = props;

  return (
    <div
      {...props}
      onClick={onClick}
      className={`${
        type === "confirmed"
          ? "bg-[#CAFFDC]"
          : type === "refund"
          ? "bg-[#FFE2E2]"
          : type === "waiting"
          ? "bg-[#EAEAEA]"
          : type === "start"
          ? "bg-[#D8FCEF]"
          : "bg-primary"
      } w-auto h-[27px] rounded-md text-center flex items-center justify-center px-4 ${
        type ? "hover:cursor-default" : "hover:cursor-pointer"
      } ${className}`}
    >
      <p
        className={`${
          type === "confirmed"
            ? "text-[#009833]"
            : type === "refund"
            ? "text-[#FF0000]"
            : type === "waiting"
            ? "text-[#676979]"
            : type === "start"
            ? "text-[#0B9442]"
            : "text-white"
        } font-medium text-[15px]`}
      >
        {children}
      </p>
    </div>
  );
};

const Member_Button = (props) => {
  const { t } = useTranslation("common");

  return (
    <div
      {...props}
      onClick={props.onClick}
      className=" sm1:w-[160px] w-[130px] lg2:h-[60px] sm1:h-[50px] h-[50px] flex flex-col items-center justify-center rounded-md bg-darkShadow"
    >
      <div className="flex items-baseline">
        <span className="text-white text-xl font-semibold">{props.value1}</span>
        <span className="text-white text-lg font-medium">{props.value2}</span>
      </div>
      <p className="text-white lg2:text-[14px] text-[13px]">{props.title}</p>
    </div>
  );
};

const Guest_Button = (props) => {
  const { t } = useTranslation("common");

  return (
    <div
      {...props}
      onClick={props.onClick}
      className="sm1:w-[160px] w-[130px] lg2:h-[60px] sm1:h-[50px] h-[50px] flex flex-col items-center justify-center rounded-md bg-shadow"
    >
      <div className="flex items-baseline">
        <span className="text-darkShadow text-xl font-semibold">
          {props.value1}{" "}
        </span>
        <span className="text-darkShadow text-lg font-medium">
          {" "}
          {props.value2}
        </span>
      </div>
      <p className="text-darkShadow lg2:text-[14px] text-[13px]">
        {props.title}
      </p>
    </div>
  );
};

export {
  PrimaryButton,
  Participate_Button,
  Confirm_Button,
  Member_Button,
  Guest_Button,
  MuiButton,
  Link,
};
