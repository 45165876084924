import axios from "axios";
import { Storage } from "@utils";
import { API_URL, API_KEY } from "@utils/config";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers["X-Gravitee-Api-Key"] = API_KEY;
axios.defaults.headers.common.Accept = "application/json";
axios.defaults["timeout"] = 1000000;

const urlBuilder = (path) => {
  return `${API_URL}${path}`;
};

const getToken = async () => {
  let userToken = await Storage.get(Storage.USER_TOKEN);
  let parseData = JSON.parse(userToken);
  if (parseData) {
    return parseData;
  }
  return null;
};

export const httpsAPICall = async (route, data, method = "get") => {
  const token = await getToken();

  if (token) {
    axios.defaults.headers.common = {
      token: `${token}`,
    };
  }
  return await axios[method](urlBuilder(route), data)
    .then((res) => {
      if (res) {
        return res;
      }
    })
    .catch((err) => {
      return err.response;
    });
};
