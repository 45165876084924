import {
  LOGIN_USER,
  REGISTER_USER,
  CLUB_LIST,
  MAKE_HOME_CLUB,
  USER_DETAIL,
  FORGOT_PASSWORD,
} from "@utils/config";
import { httpsAPICall } from "../apis";

export const loginUserApi = (data) => {
  const response = httpsAPICall(LOGIN_USER, data, "post");
  return response;
};

export const registerUserAPi = (data) => {
  const response = httpsAPICall(REGISTER_USER, data, "post");
  return response;
};

export const forgotPasswordApi = (data) => {
  const response = httpsAPICall(FORGOT_PASSWORD, data, "post");
  return response;
};

export const userDetailApi = (data) => {
  const response = httpsAPICall(`${USER_DETAIL}/${data}`, data, "get");
  return response;
};

export const clubListApi = (data) => {
  const response = httpsAPICall(
    `${CLUB_LIST}?filter=${data.filterText}&page_size=${data.pageSize}&page_no=${data.pageNo}`,
    data,
    "get"
  );
  return response;
};

export const makeHomeClubApi = (data) => {
  const response = httpsAPICall(MAKE_HOME_CLUB, data, "post");
  return response;
};
