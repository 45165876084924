import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getTournamentsData,
  getTournamentDetailData,
  putOfficialTournamentApply,
} from "./actions";

const initialState = {
  tournamentPageNo: 1,
  tournamentPrevPage: 0,
  tournamentStartDate: new Date(),
  tournamentEndDate: moment().add(10, "days").toDate(),
  display: "grid",
  tournaments: [],
  tournamentDetail: null,
  officialTournamentApply: null,
};

const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setTournamentPageNo: (state, action) => {
      state.tournamentPageNo = action.payload;
    },
    setTournamentPrevPage: (state, action) => {
      state.tournamentPrevPage = action.payload;
    },
    deletTournamentData: (state, action) => {
      state.tournaments = [];
    },
    setTournamentDate: (state, action) => {
      state.tournamentStartDate = action.payload.startDate;
      state.tournamentEndDate = action.payload.endDate;
    },
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTournamentsData.fulfilled, (state, action) => {
      if (
        action?.payload?.status === 200 &&
        action?.payload?.data?.data &&
        action?.payload?.data?.data?.tournaments?.length
      ) {
        state.tournaments = state.tournaments?.length
          ? state.tournaments.concat(action?.payload?.data?.data?.tournaments)
          : action?.payload?.data?.data?.tournaments;
      }
    });
    builder.addCase(getTournamentDetailData.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.tournamentDetail = action?.payload?.data?.data;
      }
    });
    builder.addCase(putOfficialTournamentApply.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.officialTournamentApply = action?.payload?.data?.data;
      }
    });
  },
});

export const {
  setTournamentPageNo,
  setTournamentPrevPage,
  setTournamentDate,
  setDisplay,
  deletTournamentData,
} = slice.actions;
export default slice.reducer;
