import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Zoom } from "@mui/material";
import logoIcon from "@assets/images/logo2.png";
import {
  Text,
  Img,
  CustomInput,
  MuiButton,
  Link,
  AppLoader,
} from "@components";

function LoginModal(props) {
  let {
    openModal,
    changeName,
    name,
    changeSurname,
    surname,
    changeEmail,
    email,
    changePassword,
    password,
    showPassword,
    handleShowPassword,
    btnLoading,
    nameError,
    surnameError,
    emailError,
    validEmailError,
    passwordError,
    validPasswordError,
    login,
    isRegister,
    filterText,
    changeFilterText,
    isSearchClub,
    searchClubLoading,
    homeClub,
    chooseClub,
    clubList,
    onSubmitClub,
    clubApiLoading,
    switchModal,
    isForgotPassword,
    showForgotPassword,
    handle_ForgotPassword,
    goBackLogin,
    onScroll,
    listInnerRef,
  } = props;
  const { t } = useTranslation("common");

  const ChooseClub = () => (
    <Container>
      <div className="w-[78px] h-[70px]">
        <Img src={logoIcon} />
      </div>
      <Text className="font-bold text-[19px] text-center mt-2 tracking-widest">
        {t("home.select_Club")}
      </Text>

      <FormControl sx={{ width: "90%" }}>
        <div className="w-[100%] h-auto mb-6 ">
          <OutlinedInput
            type={"text"}
            placeholder={t("home.search_Here")}
            value={filterText}
            onChange={(event) => {
              changeFilterText(event.target.value);
            }}
            sx={{ width: "100%" }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton aria-label="search" edge="end">
                  {<SearchIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        {clubList && clubList?.length ? (
          <div
            onScroll={onScroll}
            ref={listInnerRef}
            className={
              "w-[100%] h-[350px] flex flex-col items-start justify-between overflow-x-hidden overflow-y-scroll custom-scroll pt-2 pl-3 gap-y-2"
            }
          >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={homeClub}
              onChange={chooseClub}
            >
              {_.map(clubList, (val, key) => (
                <FormControlLabel
                  value={val?._id}
                  control={<Radio />}
                  label={val?.club_name}
                  key={key}
                />
              ))}

              {searchClubLoading && <p>Loading more items...</p>}
            </RadioGroup>
          </div>
        ) : searchClubLoading ? (
          <AppLoader />
        ) : (
          <Text className="text-center mt-4">{t("home.no_Club")}</Text>
        )}
      </FormControl>
      <div className="w-full h-[56px] mt-2">
        <MuiButton
          onClick={onSubmitClub}
          loading={clubApiLoading}
          isDisabled={homeClub === null ? true : false}
          height={"50px"}
          type="button"
        >
          {" "}
          {t("home.continue")}{" "}
        </MuiButton>
      </div>
    </Container>
  );

  return (
    <Dialog
      open={openModal}
      TransitionComponent={Zoom}
      PaperProps={{
        sx: {
          margin: "0%",
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        },
      }}
    >
      <>
        {" "}
        {isSearchClub ? (
          ChooseClub()
        ) : (
          <Container>
            {isForgotPassword ? (
              <div className="w-full h-auto flex flex-row items-start">
                <button onClick={goBackLogin}>
                  <ArrowBackIosNewIcon />
                  {t("home.back")}
                </button>
              </div>
            ) : null}
            <div className="w-[78px] h-[70px]">
              <Img src={logoIcon} />
            </div>
            {isForgotPassword ? (
              <div className="w-full h-auto flex flex-col items-center justify-center">
                <Text className="font-bold text-[19px] text-center mt-2 tracking-widest">
                  {t("home.forgot_Password")}
                </Text>
                <Text className="text-[16px] text-center mt-1 tracking-widest">
                  {t("home.email_ToReset")}
                </Text>
              </div>
            ) : (
              <Text className="font-bold text-[19px] text-center mt-2 tracking-widest">
                {t("home.connecting_golf")}
              </Text>
            )}

            {isRegister ? (
              <div className="w-full pt-4">
                <CustomInput
                  change_Value={changeName}
                  value={name}
                  placeholder={t("home.enter_Name")}
                  label={t("home.name")}
                  error={nameError}
                  errorMessage={nameError ? t("home.enter_Name") : ""}
                />
              </div>
            ) : (
              <></>
            )}
            {isRegister ? (
              <div className="w-full pt-4">
                <CustomInput
                  change_Value={changeSurname}
                  value={surname}
                  placeholder={t("home.enter_Surname")}
                  label={t("home.surname")}
                  error={surnameError}
                  errorMessage={surnameError ? t("home.enter_Surname") : ""}
                />
              </div>
            ) : (
              <></>
            )}

            <div className="w-full pt-4">
              <CustomInput
                change_Value={changeEmail}
                value={email}
                placeholder={t("home.enter_Email")}
                label={t("home.email")}
                error={emailError || validEmailError}
                errorMessage={
                  emailError
                    ? t("home.enter_EmailAddress")
                    : validEmailError
                    ? t("home.enter_ValidEmail")
                    : ""
                }
              />
            </div>

            {!isForgotPassword ? (
              <div className="w-full pt-4">
                <CustomInput
                  change_Value={changePassword}
                  value={password}
                  placeholder={t("home.enter_Password")}
                  label={t("home.password")}
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}
                  error={passwordError || validPasswordError}
                  errorMessage={
                    passwordError
                      ? t("home.enter_Password")
                      : validPasswordError
                      ? t("home.password_Error")
                      : ""
                  }
                  onKeyDown={(e) => e.key === "Enter" && login(e)}
                />
                {!isRegister ? (
                  <div className="w-full flex justify-end">
                    <Link onClick={showForgotPassword}>
                      <p className="text-[#7D8AF3] text-[14px] capitalize">
                        {t("home.forgot_Password2")}
                      </p>
                    </Link>
                  </div>
                ) : null}
              </div>
            ) : null}

            {isForgotPassword ? (
              <div className="w-full h-[56px] mt-10">
                <MuiButton
                  onClick={handle_ForgotPassword}
                  loading={btnLoading}
                  height={"50px"}
                  type="button"
                >
                  {" "}
                  {t("home.send")}{" "}
                </MuiButton>
              </div>
            ) : (
              <div className="w-full h-[56px] mt-10">
                <MuiButton
                  onClick={login}
                  loading={btnLoading}
                  height={"50px"}
                  type="button"
                >
                  {" "}
                  {isRegister ? t("home.signUp") : t("home.login")}{" "}
                </MuiButton>
              </div>
            )}

            {!isForgotPassword ? (
              <div className="w-full h-auto flex flex-row items-center justify-center">
                <Text className="text-sm leading-normal">
                  {isRegister ? t("home.already") : t("home.not_Member")}
                </Text>
                <Link onClick={switchModal}>
                  <p className="text-[#7D8AF3] text-[14px] capitalize">
                    {isRegister ? t("home.login") : t("home.signUp")}
                  </p>
                </Link>
              </div>
            ) : null}
          </Container>
        )}
      </>
    </Dialog>
  );
}

function CustomModal(props) {
  let { openModal, closeModal } = props;

  return (
    <Dialog
      open={openModal}
      // onClose={closeModal}
      TransitionComponent={Zoom}
      PaperProps={{
        sx: {
          margin: "0%",
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        },
      }}
    >
      <div className="w-full h-full">
        <Container2>
          <div className="w-[78px] h-[70px]">
            <Img src={logoIcon} />
          </div>
          <Text>{"Club id not found. Please add club-id in url"}</Text>
        </Container2>
      </div>
    </Dialog>
  );
}

const Container = tw.div`
lg:w-[540px] md2:w-[500px] md:w-[440px] sm2:w-[360px] sm1:w-[330px] w-[300px] 
h-auto
rounded-md 
bg-white 
flex flex-col items-center justify-start 
gap-1 
lg:p-8 p-4
max-h-[700px]
overflow-x-hidden
overflow-y-scroll
custom-scroll
`;

const Container2 = tw.div`
w-[400px] 
h-auto
rounded-md 
bg-white 
flex flex-col items-center justify-start 
gap-5 
p-5
max-h-[700px]
overflow-x-hidden
overflow-y-scroll
custom-scroll
`;

const Component = tw.div`
w-[90%]
h-[350px]
flex flex-col items-start justify-between
overflow-x-hidden
overflow-y-scroll
custom-scroll
pt-2 
gap-y-2

bg-[pink]
`;

export { LoginModal, CustomModal };
