import { createSlice } from "@reduxjs/toolkit";
import { Storage } from "@utils";
import {
  loginUser,
  registerUser,
  getClubList,
  updateClubList,
  makeHomeClub,
  getUserDetail,
} from "./actions";

const initialState = {
  userData: null,
  clubList: [],
  homeClubData: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userData = action?.payload;
    },
    logoutUser: (state) => {
      state.userData = null;
      Storage.remove(Storage.USER_DATA);
      Storage.remove(Storage.USER_TOKEN);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.userData = action?.payload?.data?.data;
        Storage.save(
          Storage.USER_DATA,
          JSON.stringify(action?.payload?.data?.data)
        );
        Storage.save(
          Storage.USER_TOKEN,
          JSON.stringify(action?.payload?.data?.data.token)
        );
      }
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.userData = action?.payload?.data?.data;
        Storage.save(
          Storage.USER_DATA,
          JSON.stringify(action?.payload?.data?.data)
        );
        Storage.save(
          Storage.USER_TOKEN,
          JSON.stringify(action?.payload?.data?.data.token)
        );
      }
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.userData = action?.payload?.data?.data;
        Storage.save(
          Storage.USER_DATA,
          JSON.stringify(action?.payload?.data?.data)
        );
      }
    });
    builder.addCase(getClubList.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.clubList = action?.payload?.data?.data?.result;
      }
    });
    builder.addCase(updateClubList.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.clubList = state.clubList.concat(
          action?.payload?.data?.data?.result
        );
      }
    });
    builder.addCase(makeHomeClub.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.homeClubData = action?.payload?.data?.data;
      }
    });
  },
});

export const { setUserDetails, logoutUser } = slice.actions;

export default slice.reducer;
