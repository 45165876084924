const SERVER_HOST = process.env.REACT_APP_API_URL;
const SERVER_API_KEY = process.env.REACT_APP_API_KEY;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const API_URL = SERVER_HOST;
export const API_KEY = SERVER_API_KEY;

export const STRIPE_PUBLISH_KEY = STRIPE_KEY;
export const GOOGLE_MAP_KEY = GOOGLE_MAP_API_KEY;

export const LOGIN_USER = "user/login";
export const REGISTER_USER = "user/register";
export const USER_DETAIL = "user/users";
export const FORGOT_PASSWORD = "user/forgotpassword";
export const TOURNAMENTS_LIST = "tournament/tournaments";
export const OFFICIAL_TOURNAMENTS_APPLY = "tournament/officialtournament/apply";
export const NEWS_FEED = "feed/feeds";
export const NEWS_FEED_LIKE = "feed/like";
export const NEWS_FEED_DISLIKE = "feed/dislike";
export const GOLF_ACITIVITY = "user/golfactivity";
export const USER_FEED = "user/golfactivity";
export const CLUB_LIST = "club/clubs";
export const MAKE_HOME_CLUB = "club/homeclub";
export const MAKE_PAYMENT = "common/payment";

const Config = {
  SERVER_HOST,
};

export default Config;
