import React, { useRef } from "react";
import GoogleMap from "google-maps-react-markers";
import "./mapStyle.css";

const Map = ({ item }) => {
  const mapRef = useRef(null);

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
  };

  const AnyReactComponent = ({ lat, lng }) => {
    return (
      <div className="w-auto h-auto">
        <div
          className={`absolute top-[-30px] left-[-15px]
               w-[40px] h-[50px] ${lat == item?.latitude ? "marker" : ""}`}
        />
      </div>
    );
  };

  return (
    <div className="min-w-[250px] w-full h-full rounded-lg flex relative ">
      <GoogleMap
        apiKey={"AIzaSyDL8YCB_yrs0zrQf982sXon1PG7nn--BqE"}
        defaultCenter={{
          lat: Number(item?.latitude),
          lng: Number(item?.longitude),
        }}
        defaultZoom={6}
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={() => {}}
      >
        <AnyReactComponent lat={item?.latitude} lng={item?.longitude} />
      </GoogleMap>
    </div>
  );
};

export default Map;
