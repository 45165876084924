import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TOURNAMENTS_LIST,
  TOURNAMENTS_DETAIL,
  OFFICIAL_TOURNAMENTS_APPLY,
} from "./types";
import {
  tournamentsApi,
  tournamentDetailApi,
  officialTournamentApplyApi,
} from "../../services/home";

export const getTournamentsData = createAsyncThunk(
  TOURNAMENTS_LIST,
  async (data) => {
    try {
      const response = await tournamentsApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const putOfficialTournamentApply = createAsyncThunk(
  OFFICIAL_TOURNAMENTS_APPLY,
  async (data) => {
    try {
      const response = await officialTournamentApplyApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getTournamentDetailData = createAsyncThunk(
  TOURNAMENTS_DETAIL,
  async (data) => {
    try {
      const response = await tournamentDetailApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);
