import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LOGIN_USER,
  REGISTER_USER,
  FORGOT_PASSWORD,
  CLUB_LIST,
  CLUB_LIST_UPDATE,
  MAKE_HOME_CLUB,
  USER_DETAIL,
} from "./types";
import {
  loginUserApi,
  registerUserAPi,
  forgotPasswordApi,
  clubListApi,
  makeHomeClubApi,
  userDetailApi,
} from "../../services/auth";

export const loginUser = createAsyncThunk(LOGIN_USER, async (data) => {
  try {
    const response = await loginUserApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const registerUser = createAsyncThunk(REGISTER_USER, async (data) => {
  try {
    const response = await registerUserAPi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  async (data) => {
    try {
      const response = await forgotPasswordApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getUserDetail = createAsyncThunk(USER_DETAIL, async (data) => {
  try {
    const response = await userDetailApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const getClubList = createAsyncThunk(CLUB_LIST, async (data) => {
  try {
    const response = await clubListApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const updateClubList = createAsyncThunk(
  CLUB_LIST_UPDATE,
  async (data) => {
    try {
      const response = await clubListApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const makeHomeClub = createAsyncThunk(MAKE_HOME_CLUB, async (data) => {
  try {
    const response = await makeHomeClubApi(data);
    return response;
  } catch (err) {
    return err;
  }
});
