import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import {
  Authentication,
  ChangeLanguage,
  LoginModal,
  TostMessage,
} from "@components";
import { Storage, isEmail, isValidatePassword } from "@utils";
import { change_Language } from "@redux/settings/slice";
import {
  loginUser,
  registerUser,
  forgotPassword,
  getClubList,
  updateClubList,
  makeHomeClub,
  getUserDetail,
} from "@redux/authentication/actions";
import { logoutUser } from "@redux/authentication/slice";
import logoIcon from "@assets/images/logo.png";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const { i18n } = useTranslation("common");
  const { userData, clubList } = useSelector((store) => store.authReducer);

  const [language, setLanguage] = useState({
    code: "GB",
    language: "en",
  });

  const [state, setState] = useState({
    loginModal: false,
    isRegister: false,
    isSearchClub: false,
    name: "",
    surname: "",
    email: "",
    password: "",
    nameError: false,
    surnameError: false,
    emailError: false,
    validEmailError: false,
    passwordError: false,
    validPasswordError: false,
    showPassword: false,
    homeClub: null,
    btnLoading: false,
    searchClubLoading: false,
    clubApiLoading: false,
    filterText: "",
    pageNo: 1,
    prevPage: 0,
    pageSize: 100,
    lastClubList: false,
    searchTimer: null,
    alertMessage: false,
    alertMessageTxt: null,
    isForgotPassword: false,
  });

  useEffect(() => {
    checkLanguage();
  }, []);

  useEffect(() => {
    if (userData?.language === "en") {
      handleLanguageChange({
        code: "GB",
        language: "en",
      });
    } else if (userData?.language === "de") {
      handleLanguageChange({
        code: "DE",
        language: "de",
      });
    }
  }, [userData]);

  async function checkLanguage() {
    try {
      let selectLanguage = await Storage.get(Storage.LANGUAGE);
      if (selectLanguage === "de") {
        setLanguage({
          code: "DE",
          language: "de",
        });
      } else {
        setLanguage({
          code: "GB",
          language: "en",
        });
      }
    } catch (error) {}
  }

  const handleLanguageChange = (props) => {
    setLanguage(props);
    i18n.changeLanguage(props.language);
    Storage.save(Storage.LANGUAGE, props.language);
    dispatch(change_Language(props.language));
  };

  const goToHome = () => {
    navigate("/");
  };

  const open_LoginModal = () => {
    setState((prev) => ({ ...prev, loginModal: true }));
  };

  const close_LoginModal = () => {
    setState((prev) => ({
      ...prev,
      loginModal: false,
      isSearchClub: false,
      name: "",
      surname: "",
      email: "",
      password: "",
      nameError: false,
      surnameError: false,
      emailError: false,
      validEmailError: false,
      passwordError: false,
      validPasswordError: false,
    }));
  };

  const switchModal = () => {
    setState((prev) => ({
      ...prev,
      isRegister: !prev.isRegister,
      name: "",
      surname: "",
      email: "",
      password: "",
      nameError: false,
      surnameError: false,
      emailError: false,
      validEmailError: false,
      passwordError: false,
      validPasswordError: false,
    }));
  };

  const change_Name = (e) => {
    setState((prev) => ({
      ...prev,
      name: e,
      nameError: false,
    }));
  };

  const change_Surname = (e) => {
    setState((prev) => ({
      ...prev,
      surname: e,
      surnameError: false,
    }));
  };

  const change_Email = (e) => {
    setState((prev) => ({
      ...prev,
      email: e,
      emailError: false,
      validEmailError: false,
    }));
  };

  const change_Password = (e) => {
    setState((prev) => ({
      ...prev,
      password: e,
      passwordError: false,
      validPasswordError: false,
    }));
  };

  const change_FilterText = (e) => {
    if (state.searchTimer) {
      clearTimeout(state.searchTimer);
    }
    if (e.length < 1) {
      setState((prev) => ({ ...prev, filterText: e }));
      filterClubList(e);
    } else {
      setState((prev) => ({
        ...prev,
        filterText: e,
        searchTimer: setTimeout(() => {
          filterClubList(e);
        }, 1400),
      }));
    }
  };

  const handle_ShowPassword = () => {
    setState((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  };

  const onLogin = useCallback(
    async (e) => {
      e.preventDefault();

      if (state.isRegister) {
        const { name, surname, email, password } = state;
        if (!name || !surname || !email || !password || !isEmail(email)) {
          setState((prev) => ({
            ...prev,
            nameError: !name,
            surnameError: !surname,
            emailError: !email,
            passwordError: !password,
            validEmailError: !isEmail(email),
          }));
          return;
        }
        if (!isValidatePassword(state.password)) {
          setState((prev) => ({
            ...prev,
            validPasswordError: true,
          }));
          return;
        }

        const apiData = {
          firstname: name,
          lastname: surname,
          email,
          password,
          device_token: 123456,
          device_type: "web",
          device_id: 1234,
          login_type: "email",
        };
        setState((prev) => ({
          ...prev,
          btnLoading: true,
          alertMessage: false,
        }));

        try {
          const response = await dispatch(registerUser(apiData));

          const resData = response?.payload?.data?.data;
          const status = response?.payload?.status;

          if (resData && status === 200) {
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              name: "",
              surname: "",
              email: "",
              password: "",
              isSearchClub: true,
              isRegister: false,
            }));

          } else {
            const errorMessage =
              response?.payload?.response?.data?.message ||
              response?.payload?.message ||
              response?.payload?.data?.message ||
              "A generic error occurred on the register!";
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              alertMessage: true,
              alertMessageTxt: errorMessage,
            }));
          }
        } catch (error) {
          setState((prev) => ({
            ...prev,
            btnLoading: false,
            alertMessage: true,
            alertMessageTxt: "An unexpected error occurred. Please try again.",
          }));
        }
      } else {
        const { email, password } = state;
        if (!email || !password || !isEmail(email)) {
          setState((prev) => ({
            ...prev,
            emailError: !email,
            passwordError: !password,
            validEmailError: !isEmail(email),
          }));
          return;
        }

        const apiData = {
          email,
          password,
          device_token: 123456,
          device_type: "web",
          device_id: 1234,
        };
        setState((prev) => ({
          ...prev,
          btnLoading: true,
          alertMessage: false,
        }));

        try {
          const response = await dispatch(loginUser(apiData));

          const resData = response?.payload?.data?.data;
          const status = response?.payload?.status;

          if (resData && status === 200) {
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              alertMessage: true,
              alertMessageTxt: "Login successfully",
              email: "",
              password: "",
              loginModal: false,
            }));
          } else {
            const errorMessage =
              response?.payload?.response?.data?.message ||
              response?.payload?.message ||
              response?.payload?.data?.message ||
              "A generic error occurred on the login!";
            setState((prev) => ({
              ...prev,
              btnLoading: false,
              alertMessage: true,
              alertMessageTxt: errorMessage,
            }));
          }
        } catch (error) {
          setState((prev) => ({
            ...prev,
            btnLoading: false,
            alertMessage: true,
            alertMessageTxt: "An unexpected error occurred. Please try again.",
          }));
        }
      }
    },
    [state, dispatch]
  );

  const filterClubList = async (e) => {
    const clubApidata = {
      filterText: e,
      pageSize: state.pageSize,
      pageNo: 1,
    };

    setState((prev) => ({ ...prev, searchClubLoading: true }));
    await dispatch(getClubList(clubApidata)).then((res) => {
      setState((prev) => ({ ...prev, searchClubLoading: false, pageNo: 2 }));
    });
  };

  useEffect(() => {
    const fetchClibList = async () => {
      const clubApidata = {
        filterText: state.filterText,
        pageSize: state.pageSize,
        pageNo: state.pageNo,
      };

      const response = await dispatch(updateClubList(clubApidata));
      setState((prev) => ({ ...prev, prevPage: state.pageNo }));

      if (response?.payload?.data?.data?.resultCount <= clubList?.length) {
        setState((prev) => ({ ...prev, lastClubList: true }));
        return;
      }
    };

    if (
      state.prevPage !== state.pageNo &&
      state.isSearchClub &&
      !state.lastClubList
    ) {
      fetchClibList();
    }
  }, [state.pageNo, state.prevPage, state.lastClubList, state.isSearchClub]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const roundUp = (value) => (value % 1 === 0 ? value : Math.ceil(value));
      if (roundUp(scrollTop + clientHeight) === scrollHeight) {
        setState((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));
      }
    }
  };

  const chooseClub = (event) => {
    setState((prev) => ({ ...prev, homeClub: event.target.value }));
  };

  const onSubmutGolfClub = async (e) => {
    e.preventDefault();
    const apidata = {
      club_id: state.homeClub,
    };
    setState((prev) => ({ ...prev, clubApiLoading: true }));
    await dispatch(makeHomeClub(apidata)).then((res) => {
      if (res?.payload?.data?.data && res?.payload?.status === 200) {
        userDetails();
      } else {
        setState((prev) => ({
          ...prev,
          alertMessage: true,
          alertMessageTxt:
            "A generic error occurred on the set home golf club!",
        }));
      }
    });
  };

  const userDetails = async () => {
    let userId = userData?._id;
    await dispatch(getUserDetail(userId)).then((res) => {
      setState((prev) => ({
        ...prev,
        isSearchClub: false,
        clubApiLoading: false,
        loginModal: false,
      }));
      if (res?.payload?.data?.data && res?.payload?.status === 200) {
        setState((prev) => ({
          ...prev,
          alertMessage: true,
          alertMessageTxt: "Register successfully!",
        }));
      } else {
        setState((prev) => ({
          ...prev,
          alertMessage: true,
          alertMessageTxt: "A generic error occurred on the register!",
        }));
      }
    });
  };

  const goToProfile = () => {
    setTimeout(() => {
      navigate("/profile");
    }, 100);
  };

  const onLogout = () => {
    dispatch(logoutUser());
  };

  const goBackLogin = () => {
    setState((prev) => ({
      ...prev,
      isForgotPassword: false,
      email: "",
      emailError: false,
      validEmailError: false,
    }));
  };

  const showForgotPassword = () => {
    setState((prev) => ({
      ...prev,
      isForgotPassword: true,
      email: "",
      emailError: false,
      validEmailError: false,
      password: "",
      passwordError: false,
      validPasswordError: false,
    }));
  };

  const handle_ForgotPassword = async (e) => {
    e.preventDefault();
    if (!state.email || !isEmail(state.email)) {
      setState((prev) => ({
        ...prev,
        emailError: !state.email,
        validEmailError: !isEmail(state.email),
      }));
    } else {
      const apiData = {
        email_id: state.email,
      };
      setState((prev) => ({
        ...prev,
        btnLoading: true,
      }));
      await dispatch(forgotPassword(apiData)).then((res) => {
        if (res?.payload?.data?.data && res?.payload?.status === 200) {
          setState((prev) => ({
            ...prev,
            btnLoading: false,
            alertMessage: true,
            alertMessageTxt: res?.payload?.data?.message,
            email: "",
            isForgotPassword: false,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            btnLoading: false,
            alertMessage: true,
            alertMessageTxt:
              res?.payload?.data?.message ||
              "A generic error occurred on the forgotPassword!",
          }));
        }
      });
    }
  };

  return (
    <Root>
      <Logo src={logoIcon} onClick={goToHome} />
      <Coponent>
        <Authentication
          onClick={open_LoginModal}
          userData={userData}
          userLogout={onLogout}
          userProfile={goToProfile}
        />
        <ChangeLanguage
          title={language}
          handleLanguageChange={handleLanguageChange}
        />
      </Coponent>
      <LoginModal
        openModal={
          userData === null || !userData?.home_club?._id
            ? true
            : state.loginModal
        }
        closeModal={close_LoginModal}
        changeName={change_Name}
        name={state.name}
        changeSurname={change_Surname}
        surname={state.surname}
        email={state.email}
        changeEmail={change_Email}
        password={state.password}
        changePassword={change_Password}
        showPassword={state.showPassword}
        handleShowPassword={handle_ShowPassword}
        btnLoading={state.btnLoading}
        nameError={state.nameError}
        surnameError={state.surnameError}
        emailError={state.emailError}
        validEmailError={state.validEmailError}
        passwordError={state.passwordError}
        validPasswordError={state.validPasswordError}
        login={onLogin}
        isRegister={state.isRegister}
        filterText={state.filterText}
        changeFilterText={change_FilterText}
        isSearchClub={state.isSearchClub}
        searchClubLoading={state.searchClubLoading}
        homeClub={state.homeClub}
        chooseClub={chooseClub}
        clubList={clubList}
        onSubmitClub={onSubmutGolfClub}
        clubApiLoading={state.clubApiLoading}
        switchModal={switchModal}
        isForgotPassword={state.isForgotPassword}
        showForgotPassword={showForgotPassword}
        handle_ForgotPassword={handle_ForgotPassword}
        goBackLogin={goBackLogin}
        onScroll={onScroll}
        listInnerRef={listInnerRef}
      />

      {state.alertMessageTxt && (
        <TostMessage
          open={state.alertMessage}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              alertMessage: false,
              alertMessageTxt: null,
            }))
          }
          title={state.alertMessageTxt}
          type={"info"}
        />
      )}
    </Root>
  );
}

const Root = tw.div`
bg-white 
md:h-[100px] h-auto 
w-[100%] 
flex flex-row md:items-center items-start justify-between 
xl3:px-40 xl2:px-36 xl1:px-28 xl:px-24 
lg2:px-20 lg:px-16
md2:px-12 md:px-10
sm2:px-6 sm:px-4
px-3
md:pt-0 pt-6 
`;

const Logo = tw.img`
w-[130px] 
h-[40px]
hover:cursor-pointer
`;

const Coponent = tw.div`
w-auto
h-auto 
flex md:flex-row flex-col-reverse md:items-center items-end md:justify-center justify-start 
md:self-center self-end
md:gap-4 gap-3 

`;
